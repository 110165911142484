import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import {
  CeRequirementFieldsOptionsProfession,
  CeRequirementFieldsOptionsQuery,
} from "./__generated__/useCeRequirementFieldsOptions";

export type { CeRequirementFieldsOptionsQuery as CeRequirementFieldsOptions };

export const CE_REQUIREMENT_FIELDS_OPTIONS_QUERY = gql`
  query CeRequirementFieldsOptions {
    ceRequirementsOptions {
      profession {
        value
        label
      }
    }
  }
`;

export type CeRequirementProfession = Omit<CeRequirementFieldsOptionsProfession, "__typename">;
const DEFAULT_PROFESSIONS: CeRequirementProfession[] = [];

export function useCeRequirementFieldsOptions() {
  const { data, loading } = useEnhancedQuery<CeRequirementFieldsOptionsQuery>(
    CE_REQUIREMENT_FIELDS_OPTIONS_QUERY
  );

  const profession = data?.ceRequirementsOptions.profession || DEFAULT_PROFESSIONS;

  return {
    professionOptions: profession,
    loading,
  };
}
