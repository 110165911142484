interface HasTitle {
  title?: string | null;
}

export const sortByTitle = (s1: HasTitle, s2: HasTitle) => {
  const s1Title = s1?.title?.toLowerCase() || "";
  const s2Title = s2?.title?.toLowerCase() || "";

  if (s1Title >= s2Title) {
    return 1;
  }
  return -1;
};
