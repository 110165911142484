/* eslint-disable react/destructuring-assignment */

import React, { useMemo } from "react";
import { useUserFieldsOptions } from "hooks/useUserFieldsOptions";
import {
  SelectGroupField,
  SelectGroupFieldProps,
  SelectGroup,
  SelectGroupProps,
} from "./InputGroup";

// CE States (to match old URLs)
export const getStateValue = (stateName: string) => stateName.toLowerCase().replace(" ", "-");

type CEStateSelectGroupProps = Omit<SelectGroupProps, "options">;

export function CEStateSelectGroup(props: CEStateSelectGroupProps) {
  const { stateOptions } = useUserFieldsOptions();
  const ceStateOptions = useMemo(
    () => stateOptions.map(({ label }) => ({ label, value: getStateValue(label) })),
    [stateOptions]
  );
  return <SelectGroup {...props} options={ceStateOptions} />;
}

type CEStateSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function CEStateSelectGroupField(props: CEStateSelectGroupFieldProps) {
  const { stateOptions } = useUserFieldsOptions();
  const ceStateOptions = useMemo(
    () => stateOptions.map(({ label }) => ({ label, value: getStateValue(label) })),
    [stateOptions]
  );
  return <SelectGroupField {...props} options={ceStateOptions} />;
}

// State
type StateSelectGroupProps = Omit<SelectGroupProps, "options">;

export function StateSelectGroup(props: StateSelectGroupProps) {
  const { stateOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={stateOptions} />;
}

type StateSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function StateSelectGroupField(props: StateSelectGroupFieldProps) {
  const { stateOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={stateOptions} />;
}

// Country
type CountrySelectGroupProps = Omit<SelectGroupProps, "options">;

export function CountrySelectGroup(props: CountrySelectGroupProps) {
  const { countryOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={countryOptions} />;
}

type CountrySelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function CountrySelectGroupField(props: CountrySelectGroupFieldProps) {
  const { countryOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={countryOptions} />;
}

// Degree
type DegreeSelectGroupProps = Omit<SelectGroupProps, "options">;

export function DegreeSelectGroup(props: DegreeSelectGroupProps) {
  const { degreeOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={degreeOptions} />;
}

type DegreeSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function DegreeSelectGroupField(props: DegreeSelectGroupFieldProps) {
  const { degreeOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={degreeOptions} />;
}

// License State
type LicenseStateSelectGroupProps = Omit<SelectGroupProps, "options">;

export function LicenseStateSelectGroup(props: LicenseStateSelectGroupProps) {
  const { licenseStateOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={licenseStateOptions} />;
}

type LicenseStateSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function LicenseStateSelectGroupField(props: LicenseStateSelectGroupFieldProps) {
  const { licenseStateOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={licenseStateOptions} />;
}

// License Type
type LicenseTypeSelectGroupProps = Omit<SelectGroupProps, "options">;

export function LicenseTypeSelectGroup(props: LicenseTypeSelectGroupProps) {
  const { licenseTypeOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={licenseTypeOptions} />;
}

type LicenseTypeSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function LicenseTypeSelectGroupField(props: LicenseTypeSelectGroupFieldProps) {
  const { licenseTypeOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={licenseTypeOptions} />;
}

// Name Prefix
type NamePrefixSelectGroupProps = Omit<SelectGroupProps, "options">;

export function NamePrefixSelectGroup(props: NamePrefixSelectGroupProps) {
  const { namePrefixOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={namePrefixOptions} />;
}

type NamePrefixSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function NamePrefixSelectGroupField(props: NamePrefixSelectGroupFieldProps) {
  const { namePrefixOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={namePrefixOptions} />;
}

// Profession
type ProfessionSelectGroupProps = Omit<SelectGroupProps, "options">;

export function ProfessionSelectGroup(props: ProfessionSelectGroupProps) {
  const { professionOptions } = useUserFieldsOptions();
  return <SelectGroup {...props} options={professionOptions} />;
}

type ProfessionSelectGroupFieldProps = Omit<SelectGroupFieldProps, "options">;

export function ProfessionSelectGroupField(props: ProfessionSelectGroupFieldProps) {
  const { professionOptions } = useUserFieldsOptions();
  return <SelectGroupField {...props} options={professionOptions} />;
}
