// Disallow redirecting to external domains
import { makeUrl } from "@uplift-ltd/strings";
import { COURSE_URL_BASE, PACKAGES_URL } from "constants/urls";

export const goToSafeUrl = (nextPage: string, defaultPage: string) => {
  try {
    window.location.assign(`//${window.location.host}${nextPage}`);
  } catch {
    window.location.assign(defaultPage);
  }
};

export const fullyQualifiedUrl = (path = ""): string => {
  return `${window.location.origin}/${path}`;
};

export const currentUrlWithoutQueryString = (): string => {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
};

interface MakeUrlProps {
  __typename: "Course" | "CourseGroup";
  slug: string | null;
}

export const makeCourseOrGroupUrl = (courseOrGroup: MakeUrlProps): string => {
  if (courseOrGroup.__typename === "Course") {
    return makeUrl(COURSE_URL_BASE, { slug: courseOrGroup.slug || "" });
  }
  return makeUrl(PACKAGES_URL, { slug: courseOrGroup.slug || "" });
};
