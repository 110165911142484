import React from "react";
import Heading from "components/common/Heading";

type CERequirementsHeaderProps = {
  title: string;
  children?: React.ReactNode;
};

const CERequirementsHeader = ({ title, children }: CERequirementsHeaderProps) => (
  <div className="bg-zur-secondary-light text-zur-primary text-center font-bold">
    <div className="container mx-auto py-16 px-5 max-w-lxl flex flex-col items-center">
      <Heading tag="h1">{title}</Heading>
      {children}
    </div>
  </div>
);

export default CERequirementsHeader;
