import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import { makeUrl } from "@uplift-ltd/strings";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import React from "react";
import Container from "components/common/Container";
import {
  EnhancedFormik,
  Form,
  FormStatus,
  SelectGroup,
  SubmitButton,
} from "components/common/Form";
import { CEStateSelectGroup, getStateValue } from "components/common/Form/selects";
import Heading from "components/common/Heading";
import HtmlContent from "components/common/HtmlContent";
import Link from "components/common/Link";
import Loading from "components/common/Loading";
import {
  BOARD_APPROVAL_URL,
  BUY_TOKENS_URL,
  CE_REQUIREMENTS_DETAIL_URL,
  CE_REQUIREMENTS_SLUG,
} from "constants/urls";
import { SeoPageProps } from "helpers/seo";
import { sortByTitle } from "helpers/sorts";
import { makeCourseOrGroupUrl } from "helpers/urls";
import { usePageMeta } from "hooks";
import {
  CeRequirementProfession,
  useCeRequirementFieldsOptions,
} from "hooks/useCeRequirementFieldsOptions";
import useRouterNavigation from "hooks/useRouterNavigation";
import { useUserFieldsOptions } from "hooks/useUserFieldsOptions";
import CERequirementsHeader from "./CERequirementsHeader";
import {
  GetCeRequirementsQueryFulfilledByCourses,
  GetCeRequirementsQueryQuery as GetCERequirementsQuery,
} from "./__generated__/index";
import styles from "./CeRequirements.module.css";

export type { GetCERequirementsQuery };

export const makeCeId = (state: string, profession: string) =>
  `${CE_REQUIREMENTS_SLUG}/${state?.toLowerCase()}/${profession?.toLowerCase()}${
    profession ? "/" : ""
  }`;

export const CE_REQUIREMENTS_QUERY = gql`
  query GetCERequirementsQuery($id: ID!) {
    ceRequirement(id: $id, idType: URI) {
      id
      ceRequirementsFields {
        requirement
        other
        fulfilledByCourses {
          ... on Course {
            id
            title
            slug
            displayInCatalog
          }
          ... on CourseGroup {
            id
            title
            slug
            displayInCatalog
          }
        }
      }
    }
  }
`;

interface CeRequirementsFormValues {
  state: string;
  profession: string;
}

interface CeRequirementsProps {
  state?: string;
  profession?: string;
}

export type CeRequirementsParams = {
  state: string;
  profession: string;
};

type CeRequirementsProfessionProps = {
  professionOptions: CeRequirementProfession[];
};

type CeRequirementsProfessionLinksProps = CeRequirementsProfessionProps & {
  state: string;
};

type CeRequirementsInnerProps = CeRequirementsProps & CeRequirementsProfessionProps;

export const CeRequirementsInner = ({
  state = "",
  profession = "",
  professionOptions,
}: CeRequirementsInnerProps) => {
  const { push } = useRouterNavigation();

  return (
    <EnhancedFormik<CeRequirementsFormValues>
      initialValues={{ state, profession }}
      onSubmit={(values, { setFormError }) => {
        setFormError(null);
        if (!values.state || !values.profession) return;
        push(
          makeUrl(CE_REQUIREMENTS_DETAIL_URL, {
            state: values.state,
            profession: values.profession,
          })
        );
      }}
    >
      {({ values, setValues }) => (
        <Form className={styles.form}>
          <FormStatus />
          <SelectGroup
            className={styles.select}
            placeholder="What's your profession?"
            label=""
            name="profession"
            value={values.profession}
            onChange={e => setValues({ ...values, profession: e.target.value })}
            options={professionOptions}
          />
          <CEStateSelectGroup
            className={styles.select}
            placeholder="Select a US state"
            label=""
            name="state"
            value={values.state}
            onChange={e => setValues({ ...values, state: e.target.value })}
          />
          <SubmitButton className={styles.submit}>
            Go <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
          </SubmitButton>
        </Form>
      )}
    </EnhancedFormik>
  );
};

export const CeRequirementsProfessionLinks = ({
  state,
  professionOptions,
}: CeRequirementsProfessionLinksProps) => {
  return (
    <>
      <Heading tag="h2" className="text-zur-primary">
        Choose your profession to see details...
      </Heading>
      <ul className={styles.ceReqsProfessionLinks}>
        {professionOptions.map(profession => (
          <li key={profession.value} className={styles.ceReqsProfessionLink}>
            <Link
              href={makeUrl(CE_REQUIREMENTS_DETAIL_URL, {
                state,
                profession: profession.value,
              })}
            >
              {profession.label}
            </Link>
            <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
          </li>
        ))}
      </ul>
    </>
  );
};

type CeRequirementsFullfilledByCoursesProps = {
  courses: GetCeRequirementsQueryFulfilledByCourses[];
  stateName: string;
  professionName?: string;
};

const CeRequirementsFulfilledByCourses = ({
  courses,
  stateName,
  professionName,
}: CeRequirementsFullfilledByCoursesProps) => {
  const filteredCourses = courses?.filter(notEmpty).filter(course => course.displayInCatalog) || [];

  if (!filteredCourses?.length) {
    return null;
  }

  return (
    <>
      <Heading tag="h2" className="text-zur-primary">
        {professionName ? "Courses" : "All courses"} that fulfill {professionName} CE requirements
        in {stateName}
      </Heading>
      <ul className={styles.ceReqsCourseList}>
        {filteredCourses.sort(sortByTitle).map(course => (
          <li className={styles.ceReqsCourseListItem} key={course.id}>
            <Link href={makeCourseOrGroupUrl(course)} className={styles.ceReqsCourseItem}>
              {course.title}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

type CeRequirementsPageProps = SeoPageProps & CeRequirementsProps;

const CeRequirementsPage = ({ title, state = "", profession = "" }: CeRequirementsPageProps) => {
  usePageMeta(title);

  const { stateOptions } = useUserFieldsOptions();
  const { professionOptions } = useCeRequirementFieldsOptions();

  const { data, loading } = useEnhancedQuery<GetCERequirementsQuery>(CE_REQUIREMENTS_QUERY, {
    variables: {
      id: makeCeId(state, profession),
    },
  });

  const ceRequirementItem = data?.ceRequirement;

  const stateName = stateOptions.find(s => getStateValue(s.label) === state)?.label;
  const professionName = professionOptions.find(p => p.value === profession)?.label;

  return (
    <>
      <CERequirementsHeader title={title}>
        <CeRequirementsInner
          state={state}
          profession={profession}
          professionOptions={professionOptions}
        />
      </CERequirementsHeader>
      <Container>
        <div className={styles.ceReqsWrapper}>
          <div className={styles.ceReqsSubWrapper}>
            <div className={styles.ceReqsContent}>
              {stateName && professionName ? (
                <>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    loading ? (
                      <div className="min-h-[10rem]">
                        <Loading type="center" delayMs={250} />
                      </div>
                    ) : ceRequirementItem ? (
                      <div>
                        <Heading tag="h2" className="text-zur-primary">
                          How many CE units does {stateName} require?
                        </Heading>
                        <div className={styles.ceReqsTokenLink}>
                          <Link href={BUY_TOKENS_URL}>Save with Tokens</Link>
                        </div>
                        <HtmlContent>
                          {ceRequirementItem.ceRequirementsFields?.requirement}
                        </HtmlContent>
                        <Heading tag="h2" className="text-zur-primary">
                          Additional Information
                        </Heading>
                        <HtmlContent>{ceRequirementItem.ceRequirementsFields?.other}</HtmlContent>
                        <CeRequirementsFulfilledByCourses
                          courses={
                            ceRequirementItem.ceRequirementsFields?.fulfilledByCourses?.filter(
                              notEmpty
                            ) || []
                          }
                          stateName={stateName}
                          professionName={professionName}
                        />
                      </div>
                    ) : (
                      <Heading tag="h2" className="text-zur-primary">
                        No requirements available yet for {professionName}s in {stateName}.
                      </Heading>
                    )
                  }
                </>
              ) : (
                stateName && (
                  <>
                    <CeRequirementsProfessionLinks
                      state={state}
                      professionOptions={professionOptions}
                    />
                    <CeRequirementsFulfilledByCourses
                      courses={
                        ceRequirementItem?.ceRequirementsFields?.fulfilledByCourses?.filter(
                          notEmpty
                        ) || []
                      }
                      stateName={stateName}
                    />
                  </>
                )
              )}
              <div className={styles.ceReqsFooter}>
                <p>
                  See all <Link href={BOARD_APPROVAL_URL}>Institute Board Approvals</Link>
                  <br />
                  Most of our webinars satisfy criteria for live interactive CE credits
                </p>
              </div>
            </div>
            <div className={styles.ceReqsSidebox}>
              <p>
                <strong>NOTE:</strong> Individual Board Rules are subject to change. The approval
                and acceptance information we provide is believed to be accurate at the time of
                printing.
              </p>
              <p>
                Check with your State Regulatory board if you have questions or concerns not
                clarified by the information provided. Ultimately you are responsible for the
                acceptance or denial of your license renewal credit hours. See details of{" "}
                <Link href={BOARD_APPROVAL_URL}>Zur Institute&apos;s CE board approvals</Link>.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CeRequirementsPage;
